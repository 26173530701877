import GLightbox from "glightbox";

export class lightbox {
    private readonly selector: string;

    constructor(selector: string) {
        this.selector = selector;
    }

    isElementAvailable(): NodeList {
        return document.querySelectorAll(this.selector);
    }

    image(): void {
        if (this.isElementAvailable().length <= 0) {
            return void 0;
        }

        const customLightboxHTML = `
            <div id="glightbox-body" class="glightbox-container" tabindex="-1" role="dialog" aria-hidden="false">
                <div class="gloader visible"></div>
                <div class="goverlay"></div>
                <div class="gcontainer">
                    <div id="glightbox-slider" class="gslider"></div>
                    <button class="gclose gbtn" aria-label="Close" data-taborder="3">{closeSVG}</button>
                    <button class="gprev gbtn" aria-label="Previous" data-taborder="2">{prevSVG}</button>
                    <button class="gnext gbtn" aria-label="Next" data-taborder="1">{nextSVG}</button>
                </div>
            </div>`;

        // @ts-ignore
        const lightbox = GLightbox({
            selector: this.selector,
            lightboxHTML: customLightboxHTML,
            moreLength: 0,
        });
    }
}
