import { navigation } from "./components/navigation";
import { search } from "./components/search";
import { lightbox } from "./components/lightbox";

// global imports
import "bootstrap/js/dist/dropdown";
import "bootstrap/js/dist/collapse";

function init() {
    // navigations
    new navigation();

    // search
    new search();

    // image lightboxes
    const imageLightbox = new lightbox(".lightbox");
    imageLightbox.image();
}

if (document.readyState === "complete" || "interactive" || "loaded") {
    init();
} else {
    document.addEventListener("DOMContentLoaded", () => init());
}
