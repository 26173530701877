export class navigation {
    constructor() {
        this.main();
    }

    main(): void {
        // @TODO: improve this

        const mainNavigation = document.getElementById("nav-wrapper");

        mainNavigation?.style.setProperty(
            "--main-navigation-height",
            mainNavigation.parentElement
                ? mainNavigation.parentElement.parentElement?.offsetHeight +
                      "px"
                : null,
        );

        mainNavigation?.addEventListener("show.bs.collapse", (event) => {
            let elem = event.target as HTMLElement;
            let parent = elem.parentElement
                ? (elem.parentElement.parentElement as HTMLElement)
                : null;
            elem.classList.remove("d-none");
            elem.style.setProperty(
                "--main-navigation-height-mobile",
                parent?.offsetHeight + "px",
            );
            document.getElementById("top")?.classList.add("nav-open");
        });

        mainNavigation?.addEventListener("hide.bs.collapse", () => {
            document.getElementById("top")?.classList.remove("nav-open");
        });

        const mobileSearch = document.getElementById("nav-search");

        mobileSearch?.addEventListener("show.bs.collapse", (event) => {
            let elem = event.target as HTMLElement;
            let parent = elem.parentElement
                ? (elem.parentElement.parentElement as HTMLElement)
                : null;
            elem.classList.remove("d-none");
            elem.style.setProperty(
                "--main-navigation-height-mobile",
                parent?.offsetHeight + "px",
            );
            document.getElementById("top")?.classList.add("search-open");
        });

        mobileSearch?.addEventListener("hide.bs.collapse", () => {
            document.getElementById("top")?.classList.remove("search-open");
        });
    }
}
