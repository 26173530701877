type Attr = { [index: string]: string };

export interface DOMElement {
    tag: string;
    attr?: Attr;
    children?: DOMElement[];
    txt?: string;
}

export function elOf(
    tag: string,
    attr: Attr | string | null,
    children: DOMElement[] | string,
): DOMElement {
    const res: DOMElement = { tag: tag };

    if (attr != null) {
        if (typeof attr == "string") {
            attr = { class: attr };
        }
        res.attr = attr;
    }

    if (typeof children == "string") {
        res.txt = children;
    } else {
        res.children = children;
    }

    return res;
}

export function buildDom(parent: HTMLElement, elements: DOMElement[]): void {
    elements.forEach((el: DOMElement): void => {
        const tag = document.createElement(el.tag);

        if (el.attr) {
            for (const [name, val] of Object.entries(el.attr)) {
                if (name === "class" || name === "className") {
                    tag.className = val;
                } else {
                    tag.setAttribute(name, val);
                }
            }
        }

        if (el.children && el.children.length) {
            buildDom(tag, el.children);
        } else if (el.txt) {
            tag.appendChild(document.createTextNode(el.txt));
        }

        parent.appendChild(tag);
    });
}
