import { Autocomplete } from "../lib/Autocomplete";

export class search {
    constructor() {
        this.search();
    }

    search(): void {
        // search form handling
        document
            .querySelectorAll<HTMLFormElement>(".search-form")
            .forEach((searchForm) => {
                const formWrapper = searchForm.parentElement as HTMLElement;
                const input = searchForm.querySelector<HTMLInputElement>(
                    ".search-form__input",
                ) as HTMLInputElement;

                if (searchForm) {
                    Autocomplete.create(formWrapper, searchForm, input);
                }
            });
    }
}
